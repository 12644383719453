@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.footer {
  @include rmax(1200) {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__container {
    padding-top: 64px;
    padding-bottom: 36px;
    border-radius: 100px;
    @include rmax(1200) {
      border-radius: 0;
    }
  }

  &__nav {
    margin-bottom: 32px;
    @include rmax(900) {
      flex-direction: column;
    }

    ul {
      white-space: nowrap;
      font-size: 18px;
      font-weight: 500;
      @include rmax(900) {
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }

    }
  }
}