@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

$headerIndents: 24px;
$headerBg: var(--c-primary);
$textColor: var(--c-white);

$topPos: 50px;
$navResponsiveBg:var(--c-primary);

.header-blue {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  margin: 0 auto;
  padding: $headerIndents 0;
  width: 100%;
  background-color: transparent;
  transition-property: transform, padding, background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  .container {
    padding: 12px 36px;
    background-color: var(--c-primary);
    border-radius: 120px;
  }

  .logo {
    // margin-right: 91px;
    min-width: 333px;

    @include rmax(1200) {
      min-width: unset;
      max-width: 300px;
    }

    @include rmax(600) {
      max-width: 200px;
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.opened {

      .navigation__inner {
        transform: translateY(0);
        // color: var(--c-primary);
      }

    }

    &__inner {
      align-items: center;
      width: 100%;
      justify-content: space-between;
      @include rmax(1200) {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        top: $topPos;
        flex-direction: column;
        background-color: var(--c-primary);
        border-radius: 0 0 30px 30px;
        gap: 20px;
        padding: 30px;
        padding-top: 60px;
        transform: translateY(-200%);
        overflow-y: auto;
        z-index: -1;
      }



      @include rhmax(600) {
        height: calc(100svh - $topPos);

      }
    }

    &__list {
      margin: 0 auto;
      @include rmax(1200) {
        flex-direction: column;
        background-color: $navResponsiveBg;
        margin-right: 0;
        gap: 20px;
        margin: unset;
        text-align: center;

      }
      @include rhmax(600) {
        overflow-y: scroll;
      }
    }

    &__link {
      color: #fff;
      text-decoration: 1px underline transparent;
      transition: 0.3s ease-in-out;

      @include hover {
        text-decoration-color: #fff;
      }
      @include rmax(1200) {
        padding: 10px 20px;
      }

      &.true {
        text-decoration: underline;
      }
    }

    &__btns {
      display: flex;
      align-items: center;
      gap: 8px;

      @include rmax(1200) {
        flex-direction: column;
        gap: 20px;
      }
    }

    .blue-btn,
    .white-btn {
      border: 1px solid #fff;
    }

    .white-btn {
      @include hover {
        background-color: var(--c-primary);
        color: #fff !important;
      }
    }

    .blue-btn {
      margin: 0;
    }
  }

  .burger {
    display: none;
    @include rmax(1200) {
      display: block;
    }

    &__line,
    &::before,
    &::after {
      background-color: #fff !important;
    }
  }

  &.scroll {

  }


  &.hide {
    transform: translateY(calc(-100% - 30px));
  }
}
