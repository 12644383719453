@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@use './../../all/sections/process/process-items';
@use './../../all/sections/tabs/tabs';

.service-process {

  .top {
    @include rmax(768) {
      flex-wrap: wrap;
      justify-content: center;
    }

    .content {
      @include rmax(768) {
      max-width: 480px;
      text-align: center;
      }
    }

    img {
      @include rmax(768) {
       height: 100px;
       width: 100%;
       max-width: 480px;
      }
    }
  }

  .container {
    max-width: 1038px;
  }

  .grid {
    align-items: flex-start;
  }

  .process-items {
    max-width: 772px;
  }

  hr {
    border-color: rgba(#fff, 0.1);
  }

  .text-bottom {

  }
}

.products-faq {
  .maint-text {
    max-width: 1090px;
  }
}

.products-page {
  .product-catalog .grid {
    @include rmax(900) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
}

.tab-content-bg {
  background-repeat: no-repeat;
  background-size: 100% 186px;
  background-position: top center;
  padding-top: 210px;
}

.hero-projects {

  .overlay {
    &::before {
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.65) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))
    }
  }

  .hero__content {
    max-width: 780px;


  }
}

.reviews {

  .grid {
    @include rmax(900) {
      grid-template-columns: 1fr;
    }
  }

  .card {
    @include rmax(900) {
      margin: 0 auto;
      max-width: 450px;
    }

    @include rmax(600) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.projects-portfolio {
  .grid {
    @include rmax(900) {
      grid-template-columns: 1fr 1fr;
    }

    @include rmax(600) {
      grid-template-columns: 1fr;
      max-width: 500px;
      margin: 0 auto;
    }

  }
}

.filters-grid {
  position: relative;
  .filter-col {

    &.show {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
    }

    &.hide {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      width: 0;
      height: 0;
      overflow: hidden;
      transition-delay: 0.5s;
    }
  }
}

.main-top {
  padding-top: 96px;
}

.contact-section {
  padding: 80px 0;
  max-width: 1008px;
  margin: 0 auto;

  @include rmax(900) {
    padding: 40px 0;
  }

  .contact-inner {
    padding: 60px;
    border: 1px solid rgba(#000, 0.24);
    @include rmax(900) {
      padding: 30px;
    }
  }

  p {
    margin-bottom: 60px;
  }

  p, h2, a {
    @include rmax(900) {
      text-align: center;
    }
  }

  .bottom {
    gap: 40px;
    justify-content: space-between;
    @include rmax(900) {
      flex-wrap: wrap;
      justify-content: center;
    }
    a {
      display: block;
      align-self: flex-start;
      padding-left: 36px;
      border-radius: 10px;
      background-position: left center;
      outline: 2px solid transparent;
      outline-offset: 0;
      transition: 0.2s ease-in-out;

      @include hover {
        color: var(--c-primary)
      }
      &:focus {
        outline-offset: 3px;
        outline-color: var(--c-primary);
      }
    }
  }

}

.form {
  width: 383px;
  align-items: flex-end;

  .form-field {
    display: block;
    width: 100%;
    border: 1px solid rgba(#000, 0.24);
    padding: 20px;
    padding-left: 60px;
    border-radius: 10px;
    background-position: left 20px center;
    outline: 2px solid transparent;
    outline-offset: 0;
    transition: 0.2s ease-in-out;



    &::placeholder {
      font-weight: bold;
      color: rgba(#151439, 0.6);
    }

    &:focus {
      outline-offset: 3px;
      outline-color: var(--c-primary);
    }
  }

  .form-btn {
    font-size: 18px;
    font-weight: 700;
    padding: 16px 80px;
    @include rmax(900) {
      align-self: center;
    }
  }
}

.product-page-section {

  .container {
    max-width: 830px;
  }

  .link {
    padding-left: 24px;
    background-position: left center;
    background-size: 18px;
    display: block;
  }

  .fz-20 {
    @include rmax(600) {
      font-size: 16px;
  }

  }
}

.project-page-section {
  padding: 80px 0;
  @include rmax(900) {
    padding: 50px 0;
  }
  @include rmax(480) {
    padding: 30px 0;
  }
  .container {
    max-width: 1038px;

    @include rmax(900) {
      flex-wrap: wrap;
    }
  }

  .link {
    background-position: left center;
    background-size: 18px;
    display: block;
  }
}

.news-page-section {
  .container {
    max-width: 1038px;
    @include rmax(1100) {
      max-width: 800px;
    }
  }

  .inner {

    img {
      @include rmax(1100) {
        width: 100%;
        height: 300px;
      }
    }
  }

  .sidebar-title {
    @include rmax(900) {
      text-align: center;
    }
  }

  .sidebar-list {
    @include rmax(1100) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }

    @include rmax(900) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    li {
      @include rmax(600) {
        width: 100%;
      }
    }

  }

  .card {
    width: 385px;

    @include rmax(900) {
      flex-direction: column-reverse;
      width: 275px;
      flex: 1 0 0%;
      justify-content: flex-end;
    }

    @include rmax(600) {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }

    img {
      @include rmax(900) {
        width: 100%;
      }
    }
  }

  & > .flex {
    @include rmax(1100) {
      flex-wrap: wrap;
    }
  }
}

.news-all {
  .grid {
    @include rmax(900) {
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 15px;
    }

    @include rmax(480) {
      grid-template-columns: 1fr;

    }
  }

  .card-link {
    @include rmax(900) {
      padding: 10px 25px;
    }
  }

  .text-lg {
    @include rmax(900) {
      font-size: 22px !important;
    }
  }
}
