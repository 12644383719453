@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

$heroMinHeight: 500px;
$heroContentWidth: 730px;
$headerHeight: 0px;
$headerHeightMobile: 0px;

$btnPaddingY: 19px;
$btnPaddingX: 63px;
$btnFz: 18px;

.hero {

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 500px;
    height: calc(100vh - $headerHeight);
    height: calc(100svh - $headerHeight);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;

    @include rmax(900) {
      height: calc(100vh - $headerHeightMobile);
      height: calc(100svh - $headerHeightMobile);
    }

    @include rhmin(900) {
      min-height: $heroMinHeight;
    }
  }

  &__content {
    position: relative;
    z-index: 1;
    height: 100%;
    max-width: $heroContentWidth;
    margin: 0 auto;
    text-align: center;
  }

  &__title {

  }

  &__btn {
    font-size: $btnFz !important;
    padding: $btnPaddingY $btnPaddingX !important;
    @include rmax(480) {
      padding: $btnPaddingY 30px !important;
    }
  }

}