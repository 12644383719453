@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.cards-4 {

  .grid {
    @include rmax(1100) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include rmax(480) {
      grid-template-columns: 1fr;
    }
  }

}