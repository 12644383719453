@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

$headerHeight: var(--header-height);

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: var(--fz-text-normal);
  line-height: var(--lineHeight);
  letter-spacing: var(--letterSpacing);
  color: var(--color-dark-400);
  background-color: var(--color-white);
  font-optical-sizing: auto;

  &__body {
    min-width: 320px;
    min-height: var(--vh);
  }

  &.open {
    overflow: hidden;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
  overflow-x: hidden;

    &::before {
      content: '';
      position: fixed;
      z-index: -100;
      inset: 0;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
    }
}

.main {
  // padding-top: var(--header-height);
}

.section {
  position: relative;

  &.overlay {
    .container {
      position: relative;
      z-index: 10;
    }
  }
}