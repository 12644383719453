.process-items {
  position: relative;
  counter-reset: process;

  &::after {
    content: '';
    position: absolute;
    left: 14px;
    top: 15px;
    bottom: 0;
    width: 4px;
    background-color: #ebeaed;
  }

  li {

    position: relative;


    &::before {
      position: absolute;
      left: -64px;
      counter-increment: process;
      content: counter(process) ;
      display: block;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      background-color: #ebeaed;
      color: rgba(#151439, 0.4);
      z-index: 2;
    }

    &:last-of-type {
      &::after {
        content: '';
        position: absolute;
        left: -46px;
        top: 15px;
        bottom: 0;
        width: 6px;
        background-color: #fff;
        z-index: 1;
      }
    }
  }
}