@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.flex {
  display: flex;

  &-wrap {
    flex-wrap: wrap;
  }

  &-col {
    flex-direction: column;
  }

  &-reverse {
    flex-direction: row-reverse;
  }

  &-grow {
    flex: 1 0 auto;
  }

  &.col-3 {
    // flex-wrap: wrap;
    & > * {
      max-width: calc((var(--ui-gap) * 3) + (calc(var(--container-width) / 12) * 3));
      width: 100%;

      @include rmax(900) {
        // max-width: calc( (var(--container-width) / 12) * 3);
      }
    }
    @include rmax(900) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }

  &.col-2 {
    // flex-wrap: wrap;
    & > * {
      max-width: calc((var(--ui-gap) * 5) + (calc(var(--container-width) / 12) * 6));
      width: 100%;

    }
    @include rmax(900) {
      flex-wrap: wrap;
      justify-content: space-evenly;
    }
  }
}

.grid {
  display: grid;
}

.col {
  &-2 {
    grid-template-columns: repeat(2, 1fr);
  }

  &-3 {
    grid-template-columns: repeat(3, 1fr);
  }

  &-4 {
    grid-template-columns: repeat(4, 1fr);
  }
}

.align {
  &-center {
    align-items: center;
  }

  &-start {
    align-items: flex-start;
  }
}

.justify {
  &-between {
    justify-content: space-between;
  }

  &-end {
    justify-content: flex-end;
  }

  &-center {
    justify-content: center;
  }
}

.gap {
  &-1 {
    gap: 10px;
  }

  &-2 {
    gap: 20px;
  }

  &-3 {
    gap: 30px;
  }

  &-4 {
    gap: 40px;
  }

  &-md {
    gap: var(--indent-md);
  }

  &-sm {
    gap: var(--indent-sm);
  }

  &-xs {
    gap: var(--indent-xs);
  }

  &-16 {
    gap: 16px;
  }
}

.center {
  margin: 0 auto;
}

.bg {
  &-white {
    background-color: var(--c-white);
  }

  &-primary {
    background-color: var(--c-primary);
  }

  &-secondary {
    background-color: var(--c-secondary);
  }
  &-light {
    background-color: var(--c-soft-blue);
  }
}

.text-up {
  text-transform: uppercase;
}

.text {
  &-center {
    text-align: center;
  }

  &-right {
    text-align: center;
  }
}

.mb {

  &-xl {
    margin-bottom: var(--indent-xl);

    @include rmax(900) {
      margin-bottom: calc(var(--indent-xl) * 0.75);
    }

    @include rmax(900) {
      margin-bottom: calc(var(--indent-xl) * 0.55);
    }
  }

  &-lg {
    margin-bottom: var(--indent-lg);

    @include rmax(900) {
      margin-bottom: calc(var(--indent-lg) * 0.75);
    }

    @include rmax(900) {
      margin-bottom: calc(var(--indent-lg) * 0.55);
    }
  }

  &-md {
    margin-bottom: var(--indent-md);

    @include rmax(900) {
      margin-bottom: calc(var(--indent-md) * 0.75);
    }

    @include rmax(480) {
      margin-bottom: calc(var(--indent-md) * 0.55);
    }
  }

  &-sm {
    margin-bottom: var(--indent-sm);
  }

  &-xs {
    margin-bottom: var(--indent-xs);
  }

  &-3 {
    margin-bottom: 30px;
  }

  &-2 {
    margin-bottom: 20px;
  }

  &-1 {
    margin-bottom: 10px;
  }

  &-05 {
    margin-bottom: 5px;
  }

  &-mb-04 {
    margin-bottom: 4px;
  }

  &-16 {
    margin-bottom: 16px;
  }
}

.my {
  &-md {
    margin-top: var(--indent-md);
    margin-bottom: var(--indent-md);

    @include rmax(900) {
      margin-bottom: calc(var(--indent-md) * 0.75);
      margin-top: calc(var(--indent-md) * 0.75);
    }

    @include rmax(480) {
      margin-bottom: calc(var(--indent-md) * 0.55);
      margin-top: calc(var(--indent-md) * 0.55);
    }
  }
}

.mt {
  &-auto {
    margin-top: auto;
  }
  &-16 {
    margin-top: 16px;
  }
}

.mr {
  &-lg {
    margin-right: var(--indent-lg);
  }

  &-md {
    margin-right: var(--indent-md);
  }

  &-sm {
    margin-right: var(--indent-sm);
  }

  &-xs {
    margin-right: var(--indent-xs);
  }
}

.pb {
  &-xl {
    padding-bottom: var(--indent-xl);
  }

  &-lg {
    padding-bottom: var(--indent-lg);
  }

  &-md {
    padding-bottom: var(--indent-md);
  }

  &-sm {
    padding-bottom: var(--indent-sm);
  }

  &-xs {
    padding-bottom: var(--indent-xs);
  }
}

.pt {
  &-xl {
    padding-top: var(--indent-xl);

    @include rmax(900) {
      padding-top: calc(var(--indent-xl) * 0.75);
    }

    @include rmax(480) {
      padding-top: calc(var(--indent-xl) * 0.55);
    }
  }

  &-lg {
    padding-top: var(--indent-lg);

    @include rmax(900) {
      padding-top: calc(var(--indent-lg) * 0.75);
    }

    @include rmax(480) {
      padding-top: calc(var(--indent-lg) * 0.55);
    }
  }

  &-md {
    padding-top: var(--indent-md);
  }

  &-sm {
    padding-top: var(--indent-sm);
  }

  &-xs {
    padding-top: var(--indent-xs);
  }
}

.pa {
  &-lg {
    padding: var(--indent-lg);

    @include rmax(900) {
      padding: calc(var(--indent-lg) * 0.75);
    }

    @include rmax(480) {
      padding: calc(var(--indent-lg) * 0.55);
    }
  }

  &-md {
    padding: var(--indent-md);
  }

  &-sm {
    padding: var(--indent-sm);
  }

  &-xs {
    padding: var(--indent-xs);
  }

  &-3 {
    padding: 30px;
  }

}

.py {
  &-lg {
    padding-top: var(--indent-lg);
    padding-bottom: var(--indent-lg);

    @include rmax(900) {
      padding-top: calc(var(--indent-lg) * 0.75);
      padding-bottom: calc(var(--indent-lg) * 0.75);
    }

    @include rmax(480) {
      padding-top: calc(var(--indent-lg) * 0.5);
      padding-bottom: calc(var(--indent-lg) * 0.5);
    }
  }

  &-md {
    padding-top: var(--indent-md);
    padding-bottom: var(--indent-md);
  }

  &-sm {
    padding-top: var(--indent-sm);
    padding-bottom: var(--indent-sm);
  }

  &-xs {
    padding-top: var(--indent-xs);
    padding-bottom: var(--indent-xs);
  }

  &-120 {
    padding-top: 120px;
    padding-bottom: 120px;

    @include rmax(900) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    @include rmax(768) {
      padding-top: 45px;
      padding-bottom: 45px;
    }

    @include rmax(480) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.px {
  &-sm {
    padding-left: var(--indent-sm);
    padding-right: var(--indent-sm);
  }
}

.pl {
  &-xl {
    padding-left: var(--indent-xl);
  }
}

.color {
  &-white {
    color: var(--c-white);

    &-80 {
      color: rgba(#fff, 0.8);
    }
  }

  &-inherit {
    color: inherit;
  }

  &-primary {
    color: var(--c-primary);
  }

  &-secondary {
    color: var(--c-secondary);

    &-60 {
      opacity: 0.6;
    }
  }

  &-dark {
    &-40 {
      color: rgba(#151439, 0.4);
    }

    &-60 {
      color: rgba(#151439, 0.6);
    }

    &-80 {
      color: rgba(#151439, 0.8);
    }
  }

  &-green {
    color: var(--c-green) !important;
  }
}

.bdrs {
  &-4 {
    border-radius: 40px;
    @include rmax(900) {
      border-radius: calc(40px * 0.75);
    }

    @include rmax(480) {
      border-radius: calc(40px * 0.5);
    }
  }

  &-3 {
    border-radius: 30px;
  }

  &-2 {
    border-radius: 20px;
  }

  &-1 {
    border-radius: 10px;
  }

  &-main {
    border-radius: var(--radius-main);
    @include rmax(900) {
      border-radius: calc(var(--radius-main) * 0.75);
    }

    @include rmax(480) {
      border-radius: calc(var(--radius-main) * 0.5);
    }
  }

  &-sm {
    border-radius: var(--indent-sm);
  }
}

.bdr {
  &-1 {
    border-style: solid;
    border-width: 1px;
  }
  &-t-1 {
    border-top-style: solid;
    border-top-width: 1px;
  }
}

.bdrc {
  &-dark {
    &-20 {
      border-color: rgba(#151439, 0.16);
    }
  }

  &-green {
    border-color: var(--c-green) !important;
  }

  &-light {
    border-color: var(--c-soft-blue);
  }
}

.overlay {
  position: relative;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    inset: 0;
  }

  &-linear {
    &::before {
      background-image: var(--main-gradient);
    }
  }

  &-radial {
    &::before {
      background-image: var(--radial-gradient);
    }
  }

  &-colored {
    &::before {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }

  &-primary {
    &::before {
      background-color: var(--c-primary);
    }
  }

  &-op {
    &-20 {
      &::before {
        opacity: 0.2;
      }
    }
    &-80 {
      &::before {
        opacity: 0.8;
      }
    }
  }

  &-white {
    &::before {
      background-color: #fff;
    }
  }
}

.overflow {
  overflow: hidden;
}

.op {
  &-80 {
    opacity: 0.8;
  }
}

.no-hover {
  pointer-events: none;
}

.h {
  &-100 {
    height: 100%;
  }
}

.w {
  &-100 {
    width: 100%;
  }
}

.r-1200 {
  @include rmax(1200) {

    &.grid.col-3 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        width: 48%;

        @include rmax(768) {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }
}

.r-1100 {}

.r-900 {

  @include rmax(900) {

    &.grid.col-3 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      li {
        width: 48%;

        @include rmax(768) {
          width: 100%;
          max-width: 400px;
        }
      }
    }
  }
}

.r-768 {}

.r-480 {}

.lh {
  &-1 {
    line-height: 1;
  }
  &-15 {
    line-height: 1.15;
  }
  &-2 {
    line-height: 1.2;
  }
  &-25 {
    line-height: 1.25;
  }
  &-3 {
    line-height: 1.3;
  }
  &-35 {
    line-height: 1.35;
  }
  &-4 {
    line-height: 1.4;
  }
  &-45 {
    line-height: 1.45;
  }
  &-5 {
    line-height: 1.5;
  }
  &-6 {
    line-height: 1.6;
  }
}

.icon-sz {
  &-lg {

      background-repeat: no-repeat;
      background-size: var(--indent-lg);

  }

  &-sm {

      background-repeat: no-repeat !important;
      background-size: var(--indent-sm) !important;

  }
}

.icon-pos {
  &-top-left {
    background-position: left top !important;
  }

  &-y-center {
    background-position-y: center !important;
  }
}

.icon-bg {


  &-lg {
    &::before {
      content: '';
      display: block;
      min-width: var(--indent-lg);
      height: var(--indent-lg);
      aspect-ratio: 1 / 1;
      z-index: -1;
    }
  }

  &-r {
    &-xs {
      &::before {
        border-radius: var(--indent-xs);
      }
    }
  }

  &-c-light {
    &::before {
      background-color: var(--c-soft-blue);
    }
  }
}

.mw-630 {
  max-width: 631px;
}

.fz-20 {
  font-size: 20px;
}

.fz-14 {
  font-size: 14px;
}

.l-space-2 {
  letter-spacing: 2px;
}

.list-type-disc {

  li {
    list-style-position: inside;
    list-style-type: disc;
    padding-left: 10px;


    &::marker {
      margin-right: 0;
    }
  }
}

.par-mb-16,
.el-mb-16 {
  * {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}