/* stylelint-disable declaration-no-important */
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

// settings

$backgroundColor: var(--c-primary);
$bgOnHover: var(--c-white);

$textColor: var(--c-white);
$textOnHover: var(--c-primary);

$borderRadius: 30px;
$borderColor: transparent;
$borderColorOnHover: var(--c-primary);


$paddingY: 9px;
$paddingX: 16px;

$paddingLgY: 19px;
$paddingLgX: 64px;

$fontSize: 16px;
$fontWeigth: var(--fw-500);
$lineHeight: 1.3;

$borderWidth: 1px;

$outlineWidth: 2px;
$outlineColor: $backgroundColor;


*[class].btn {
  display: block;
  width: fit-content;
  border-radius: $borderRadius;
  padding: $paddingY $paddingX;
  font-weight: $fontWeigth;
  font-size: $fontSize;
  line-height: $lineHeight;
  white-space: nowrap;
  text-align: center;
  color: $textColor;
  border: $borderWidth solid $borderColor;
  background-color: $backgroundColor;
  outline: $outlineWidth solid transparent;
  transition-property: background-color, color, border-color, outline;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:focus {
    outline-color: $outlineColor;
    outline-offset: 3px;
  }

  @include hover {
    border-color: $borderColorOnHover !important;
    color: $textOnHover !important;
    background-color: $bgOnHover;
  }

  &--white {
    background-color: $textColor;
    color: $backgroundColor;
  }

  &--border {
    border: 1px solid var(--c-primary);
    color: var(--c-primary);
    background-color: transparent;

    @include hover {
      color: var(--c-white) !important;
      background-color: var(--c-primary);
    }

    &.active {
      color: var(--c-white) !important;
      background-color: var(--c-primary);
    }
  }

  &--lg {
    padding: $paddingLgY  $paddingLgX;
    font-size: 18px;
    line-height: 1;
    @include rmax(480) {
      padding: $paddingLgY 30px;
    }
  }

  &--sm {
    font-size: 14px;
    padding: 8px 16px;
  }

  &--xs {
    font-size: 14px;
    padding: 4px 16px;
  }
}
