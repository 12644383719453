

@import './3-col/index';
@import './4-col/index';

.card {

  height: 100%;

  &[class*="icon"] {
    background-position: top 72px left 30px;
    background-size: 48px;
    background-repeat: no-repeat;
  }
}


