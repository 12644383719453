@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@use '../../all/sections/cards/index';

.features-cards {

  .card {
    padding-top: 130px;
    padding-bottom: 60px;
  }
}

.solutions-cards {

  .card {
    padding-top: 95px;
    padding-bottom: 20px;

    &[class*="icon"] {
      background-position: 30px 22px;
    }
  }

  .btn--lg {
    @include rmax(900) {
      margin: 0 auto;
    }
  }
}

.services-cards {

  .card {
    padding-top: 95px;
    padding-bottom: 20px;
  }

}

.featured-products {

  .subtitle {
    @include rmax(900) {
      text-align: center;
    }
  }

  .btn--lg {
    @include rmax(900) {
      margin: 0 auto;
    }
  }

  .card {
    gap: 16px;
    @include rmax(900) {
      max-width: 600px;
      margin: 0 auto;
    }

    @include rmax(480) {
      flex-direction: column-reverse;
      // max-width: 300px;
    }

    img {
      @include rmax(480) {
        width: 100%;
        aspect-ratio: 6 / 2;
      }
    }
  }

  .grid {
    @include rmax(900) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }
}

.explore-installations {
  .btn--lg {
    @include rmax(900) {
      margin: 0 auto;
    }
  }
}

.news-hub {

  .news-card-mini {
    @include rmax(1200) {
      flex-direction: column-reverse;
    }
  }

  .btn--lg {
    @include rmax(900) {
      margin: 0 auto;
    }
  }
}

.services-solutions {

  .card {
    padding-top: 94px;
    padding-bottom: 22px;

    &[class*="icon"] {
      background-position: 30px 22px;
    }
  }
}

.inlocking-benefits {

  .container {
    max-width: 1024px;
  }

  .grid {
    max-width: 800px;
    gap: 64px;
    margin: 0 auto;

    @include rmax(768) {
      grid-template-columns: 1fr;
      gap: 30px
    }
  }

  .card {
    @include rmax(768) {
      max-width: 300px;
      margin: 0 auto;
    }
  }
}

.why-us {

  & > .grid {
    grid-template-columns: 490px 1fr;

    @include rmax(1100) {
      display: flex;
      flex-wrap: wrap;
    }

    .grid {
      @include rmax(468) {
        grid-template-columns: 300px;
        justify-content: center;
      }
    }
  }

  h2, p {
    @include rmax(768) {
      text-align: center;
    }
  }

  img {
    @include rmax(1100) {
      height: 380px;
    }

    @include rmax(768) {
      height: 300px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
    }

    @include rmax(480) {
      width: 400px;
      height: 150px;
    }
  }

  .card {
    padding: 20px;

    &.icon-bg-lg {
      background-position-x: 32px !important;
    }
  }
}