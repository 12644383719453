@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.title {

  &-lg {
    font-weight: bold;
    font-size: var(--title-lg);
    @include rmax(900) {
      font-size: calc(var(--title-lg) * 0.75);
    }

    @include rmax(480) {
      font-size: calc(var(--title-lg) * 0.55);
    }
  }
  &-md {
    font-weight: bold;
    font-size: var(--title-md);
    @include rmax(900) {
      font-size: calc(var(--title-md) * 0.75);
    }

    @include rmax(480) {
      font-size: calc(var(--title-md) * 0.55);
    }
  }
  &-sm {
    font-weight: bold;
    font-size: var(--title-sm);
    @include rmax(900) {
      font-size: calc(var(--title-sm) * 0.75);
    }

    @include rmax(480) {
      font-size: calc(var(--title-sm) * 0.55);
    }
  }
}

.text {
  &-lg {
    font-size: var(--fz-text-lg);
    @include rmax(900) {
      font-size: calc(var(--fz-text-lg) * 0.75);
    }
    @include rmax(480) {
      // font-size: calc(var(--fz-text-lg) * 0.6);
    }
  }

  &-md {
    font-size: var(--fz-text-md);
  }

  &-xs {
    font-size: var(--fz-text-xs);
  }
}

.fw {
  &-500 {
    font-weight: 500;
  }

  &-700 {
    font-weight: 700;
  }
}