@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

// $linkColor: var(--c-black);
$linkColorOnHover: var(--c-primary);
$linkFontSize: 18px;
$linkFontWeight: 500;
$gapBetweenLinks: 31px;
$outlineColor: var(--c-primary);
$menuRight: auto;

$makeResponsiveBreakpoint: 1200;
$navResponsiveBg: var(--c-white);
$topPos: 75px;

.nav {
  // position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.opened {

    .nav__inner {
      transform: translateY(0);
      color: var(--c-primary);
      transition: 0.3s ease-in-out;
    }

  }

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    transition: padding 0.3s ease-in-out;
    @include rmax($makeResponsiveBreakpoint) {
      position: absolute;
      left: 0;
      right: 0;
      width: 100%;
      top: $topPos;
      flex-direction: column;
      background-color: $navResponsiveBg;
      gap: 20px;
      padding: 30px;
      transform: translateY(-200%);
      overflow-y: auto;

    }



    @include rhmax(600) {
      height: calc(100svh - $topPos);

    }
  }

  &__list {
    display: flex;
    align-items: center;
    gap: $gapBetweenLinks;
    margin-right: $menuRight;
    @include rmax($makeResponsiveBreakpoint) {
      flex-direction: column;
      background-color: $navResponsiveBg;
      margin-right: 0;
      gap: 20px;

    }
    @include rhmax(600) {
      overflow-y: scroll;
    }
  }

  &__link {
    font-size: inherit;
    font-weight: $linkFontWeight;
    white-space: nowrap;
    text-transform: capitalize;
    color: inherit;
    background-color: transparent;
    border-radius: 3px;
    outline: 2px solid transparent;
    transition: 0.3s ease-in-out;

    &:focus {
      outline-color: $outlineColor;
      outline-offset: 3px;
    }

    @include hover {
      color: $linkColorOnHover;
    }

    &.true {
      color: $linkColorOnHover;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 8px;
    @include rmax($makeResponsiveBreakpoint) {
      flex-direction: column;
      gap: 20px;
    }

    .formsapp-popup-button {
      margin: 0 !important;
    }
  }


}

.open {
& .site-container {

    &::before {
      background-color: rgba(#000, 0.75);
      z-index: 10;
    }
  }

}