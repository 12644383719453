.faq {
  summary {
    position: relative;
    cursor: pointer;
    padding-right: 20px;
    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
      background-color: rgba(#151439, 0.4);
      mask-image: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.13878 11.3334C8.91325 11.3347 8.68969 11.2899 8.4809 11.2015C8.27211 11.1131 8.08221 10.9828 7.92208 10.8182L1.06745 3.71194C0.90767 3.54629 0.780927 3.34965 0.694455 3.13322C0.607984 2.9168 0.563477 2.68483 0.563477 2.45058C0.563477 2.21632 0.607984 1.98436 0.694455 1.76793C0.780927 1.55151 0.90767 1.35486 1.06745 1.18922C1.22723 1.02358 1.41691 0.892179 1.62568 0.802533C1.83444 0.712887 2.05819 0.666748 2.28415 0.666748C2.51011 0.666748 2.73386 0.712887 2.94262 0.802533C3.15138 0.892179 3.34107 1.02358 3.50084 1.18922L9.13878 7.06964L14.7939 1.42017C14.9515 1.23845 15.1436 1.0924 15.3582 0.99116C15.5728 0.889921 15.8052 0.835669 16.0409 0.831798C16.2766 0.827926 16.5106 0.874517 16.7281 0.968659C16.9457 1.0628 17.1422 1.20247 17.3053 1.37892C17.4684 1.55538 17.5946 1.76483 17.6761 1.99417C17.7576 2.22352 17.7925 2.46782 17.7788 2.71181C17.765 2.95579 17.7028 3.19421 17.5962 3.41215C17.4895 3.63009 17.3405 3.82287 17.1587 3.97842L10.3041 10.8359C9.99068 11.1492 9.57406 11.327 9.13878 11.3334Z' fill='%23222222'/%3E%3C/svg%3E%0A");
      mask-repeat: no-repeat;
      mask-size: contain;
      mask-position: center;
      transition: 0.15s ease-in-out;
    }
  }

  details {
    &[open] {
      summary {
        &::before {
          transform: scaleY(-1);
          background-color: var(--c-primary);
        }
      }
    }
  }
}