@mixin rmin($width) {
  @media (min-width: #{$width}px) {
    @content;
  }
}

@mixin rhmin($width) {
  @media (min-height: #{$width}px) {
    @content;
  }
}

@mixin rhmax($width) {
  @media (max-height: #{$width}px) {
    @content;
  }
}

@mixin rmax($width) {
  @media (max-width: #{$width}px) {
    @content;
  }
}

@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }

  }
}