@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

$headerIndents: var(--header-indents);
$headerBg: transparent;

.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 20;
  margin: 0 auto;
  padding: $headerIndents 0;
  width: 100%;
  background-color: $headerBg;
  transition: padding 0.3s ease-in-out, background-color 0.2s ease-in-out, transform 0.2s ease-in-out;

  &__logo {
    margin-right: 86px;
    min-width: 333px;

    @include rmax(1100) {
      min-width: unset;
      max-width: 300px;
    }
  }

  &.scroll {
    padding: 10px 0;
    background-color: #fff;
    color: #000;
    box-shadow: 0 4px 60px 3px rgba(#000, 0.15);

    .logo__text {
      filter: invert(1);
    }

    .nav__inner {
      @include rmax(1100) {
        top: 0;
        padding-top: 70px;
        z-index: -1;
      }
    }
  }


  &.hide {
    transform: translateY(calc(-100% - 30px));
  }
}

.open {

  .header {
    background-color: #fff;

    .logo__text {
      filter: invert(1)
    }
  }
}