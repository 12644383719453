@use "../utils/functions/"as*;
@use "../utils/mixins"as*;
@use '../../all/sections/heros/1-col/index';

h2 {
  line-height: 1.2;
}

.hero-home {

  .hero__content {
    padding-top: 60px;

    @include rmax(900) {
      padding-top: 0;
    }
  }

  .hero__title {
    margin-bottom: 60px;

    @include rmax(900) {
      margin-top: 30px;
    }
  }
}

.page-main {

  .about-us {

    .title-lg {
      margin-bottom: 20px;
      line-height: 1.6;
    }
  }

  .featured-products {
    .grid {
      gap: 44px 24px;

      @include rmax(900) {
        gap: 15px;
      }
    }
  }

  .installation-card {
    .text {
      line-height: 1.65;
    }
  }
}

.page-services {
  .inlocking-benefits {
    h2 {
      line-height: 1;
    }

    .grid {
      gap: 56px 64px;

      @include rmax(900) {
        gap: 20px;
      }
    }
  }

  .why-us {

    &.py-120 {
      @include rmax(1200) {
        padding-top: 60px;
        padding-bottom: 60px;
      }

      @include rmax(768) {
        padding-top: 30px;
        padding-bottom: 30px;
      }
    }

    h2 {
      line-height: 1;
      margin-bottom: 77px;

      @include rmax(1200) {
        margin-bottom: 25px;
      }
    }

    .text-lg {
      margin-bottom: 48px;
    }

    .text-20 {
      font-size: 20px;
    }
  }

  .service-process {
    padding-bottom: 90px;

    @include rmax(900) {
      padding-bottom: 45px;
    }

    .process-items {
      line-height: 1.65;
    }
  }
}

.page-projects,
.page-products {
  &:not(.open) {
    .header:not(.scroll) {
      .burger {

        .burger__line,
        &::before,
        &::after {
          background-color: #fff;
        }
      }
    }
  }
}

.page-products {




  .tabs__link {
    font-size: 18px;
  }

  .featured-products {
    .grid {
      gap: 44px 24px;

      @include rmax(900) {
        gap: 15px;
      }
    }
  }
}

.page-projects {
  .showcases {
    .main-title {
      max-width: 740px;
      margin: 0 auto;
      padding-top: 20px;
      margin-bottom: 25px;
    }

    .main-text {
      max-width: 700px;
      margin: 0 auto;
      margin-bottom: 60px;

      @include rmax(900) {
        margin-bottom: 30px;
      }
    }

    @include rmax(900) {
      .col-3 li {
        max-width: 750px;
        width: 100%;

        img {
          height: 300px;
        }
      }
    }

    ul {
      line-height: 1.6;
    }
  }

  .showcase-card {

    img {
      width: 100%;
    }
  }

  .projects-portfolio {
    padding-top: 70px;
  }

  .filters {
    .btn {
      font-size: 18px;
    }
  }

  .filters-grid {
    @include rmax(1200) {
      gap: 20px;
    }

    img {
      width: 100%;
    }
  }

  .reviews {
    .grid {
      font-size: 20px;
      line-height: 1.6;
    }
  }
}