/* stylelint-disable no-unknown-animations */
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

$respBreakpoint: 1200;

.burger {
  display: none;
  min-width: 36px;
  height: 30px;
  position: relative;
  z-index: 10;
  color: #000;
  cursor: pointer;
}
@include rmax($respBreakpoint) {
  .burger {
    display: block;
  }
}
.burger__line {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transition: transform 0.3s ease-in-out;
}
.burger::before, .burger::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: currentColor;
  transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
}

.burger::before {
  top: 0;
  border-radius: 2px 2px 0 0;
}

.burger::after {
  top: calc(100% - 2px);
  border-radius: 0 0 2px 2px;
}

.page.open {


  .burger .burger__line {
    transform: scale(0);
    transition: transform 0.3s ease-in-out;
    background-color: #000;
  }
  .burger::before, .burger::after {
    background-color: #000;
    transform: rotate(45deg);
    top: 50%;
    transition: transform 0.3s ease-in-out, top 0.3s ease-in-out;
    border-radius: 0;
  }
  .burger::after {
    transform: rotate(-45deg);
  }
}