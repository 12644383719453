@use "../../../../scss/utils/functions/"as*;
@use "../../../../scss/utils/mixins/"as*;

.cards-3 {

  .grid {
    @include rmax(768) {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      @include rmax(768) {
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
      }
    }

  }

}