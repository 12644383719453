/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

:root {
  --content-width: 1216px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  --ui-gap: 30px;


  --header-indents: 24px;
  // font-family
  --font-family-primary: "DM Sans", sans-serif;
  // --font-family-secondary: "PlusJakarta", sans-serif;

  --lineHeight: 1.5;
  --letterSpacing: 0;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-700: 700;

  // colors
  --c-white: #fff;
  --c-black: #000;

  --c-primary: #482BE7;
  --c-secondary: #1E0E62;

  --c-soft-blue:  #E8EEFF;
  --c-dark-blue: #151439;

  --c-green: #219653;

  --radius-main: 32px;

  --fz-text-lg: 22px;
  --fz-text-normal: 16px;
  --fz-text-xs: 12px;

  // text & titles
  --title-lg: 56px;
  --title-md: 42px;
  --title-sm: 32px;


  --indent-xl: 60px;
  --indent-lg: 48px;
  --indent-md: 36px;
  --indent-sm: 24px;
  --indent-xs: 12px;
}