@use "../../../../../scss/utils/functions/"as*;
@use "../../../../../scss/utils/mixins/"as*;

.side-img {

  &__container {
    @include rmax(768) {
      grid-template-columns: 1fr;
    }
  }

  .image {
    @include rmax(768) {
      width: 100%;
      height: 300px;
    }

    @include rmax(480) {
      height: 200px;
    }
  }

  &:not(:last-child) {
    @include rmax(768) {
      margin-bottom: 30px;
    }
  }

  &.reversed {
    .image {
      order: 2;
      @include rmax(768) {
        order: 0;
      }
    }
  }

}