@mixin font-woff($font-family, $url, $weight, $style) {
  @font-face {
    src: url('#{$url}.woff') format("woff");
    font-family: "#{$font-family}";
    font-weight: #{$weight};
    font-style: $style;
    font-display: swap;
  }
}

@mixin font-woff2($font-family, $url, $weight, $style) {
  @font-face {
    src: url('#{$url}.woff2') format("woff2");
    font-family: "#{$font-family}";
    font-weight: #{$weight};
    font-style: $style;
    font-display: swap;
  }
}

@mixin font-ttf($font-family, $url, $weight, $style) {
  @font-face {
    src: url('#{$url}.ttf?as=woff2') format("woff2");
    font-family: "#{$font-family}";
    font-weight: #{$weight};
    font-style: $style;
    font-display: swap;
  }
}