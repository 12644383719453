@use "../../../scss/utils/functions/"as*;
@use "../../../scss/utils/mixins/"as*;

.policy {
  padding: 80px 0;

  @include rmax(900) {
    padding: 50px 0;
  }

  .container {
    max-width: 1008px;
  }
  &__content {

    font-size: 20px;
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }

  h1 {
    font-size: 50px;
    color: #073E57;
    font-weight: bold;
    width: 100%;
    text-align: center;
    margin-bottom: 48px;
    @include rmax(900) {
      margin-bottom: 30px;
    }
  }

  h2 {
    margin-bottom: 24px;
    font-size: 30px;
    font-weight: bold;
    color: #007EDA;
    @include rmax(900) {
      font-size: 28px;
      margin-bottom: 15px;
    }
  }

  ul {
    margin-bottom: 24px;
    padding-left: 15px;
  }

  li {
    padding-left: 5px;
    font-size: 18px ;
    line-height: 1.5;
    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &::marker {
      // content: '\2713';
      content: '✓';
      margin-right: 5px;
    }
  }

  p {
    // font-size: 20px;
    // line-height: 1.2;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-secondary);
  }

  b,
  strong {
    font-weight: var(--fw-600);
  }

}